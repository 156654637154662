import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useExperiments, useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {isLoadMoreButtonImprovementsEnabled} from '../../../../../commons/selectors/experiments'
import {useSettings} from '../../../hooks/use-settings'
import settingsParams from '../../../settingsParams'
import {useWidgetActions} from '../../hooks/state-provider'
import {Spinner} from '../spinner'
import s from './load-more-button.scss'

export const LoadMoreButton = ({
  mobile,
  hasMore,
  moreLoading,
}: {
  mobile?: boolean
  hasMore: boolean
  moreLoading: boolean
}) => {
  const {t} = useTranslation()
  const {experiments} = useExperiments()
  const {loadEventsPage} = useWidgetActions()
  const {get: getSetting} = useSettings()
  const limit = getSetting(settingsParams.eventsPerPage)
  const loadMoreButtonImprovementsEnabled = isLoadMoreButtonImprovementsEnabled(experiments)

  if (!hasMore && !moreLoading) {
    return null
  }

  if (moreLoading && !loadMoreButtonImprovementsEnabled) {
    return (
      <div data-hook={DH.loadMoreButtonContainer} className={s.loader}>
        <Spinner />
      </div>
    )
  }

  return (
    <div
      data-hook={DH.loadMoreButtonContainer}
      className={classNames({
        [s.container]: !loadMoreButtonImprovementsEnabled,
        [s.container_LoadMoreButtonImprovements]: loadMoreButtonImprovementsEnabled,
        [s.mobile]: mobile,
      })}
    >
      {moreLoading && loadMoreButtonImprovementsEnabled && (
        <div className={s.spinnerWrapper}>
          <Spinner />
        </div>
      )}
      <button
        data-hook={DH.loadMoreButton}
        className={classNames(s.button, {
          [s.hidden]: moreLoading && loadMoreButtonImprovementsEnabled,
          [s.button_LoadMoreButtonImprovements]: loadMoreButtonImprovementsEnabled,
          [s.mobile]: mobile,
        })}
        type="button"
        onClick={() => loadEventsPage(loadMoreButtonImprovementsEnabled ? {limit} : {})}
      >
        {t('loadMoreButton')}
      </button>
    </div>
  )
}
