import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useExperiments} from '@wix/yoshi-flow-editor'
import React, {useEffect, useReducer, useRef} from 'react'
import {isLoadMoreButtonImprovementsEnabled} from '../../../../../commons/selectors/experiments'
import {useEventsList} from '../../hooks/use-events-list'
import {LoadMoreButton} from '../load-more-button'
import {MainListTitle} from '../main-list-title'
import {Card} from './card'
import s from './cards.scss'
import {CardsProps} from '.'

export const Cards = ({t}: CardsProps) => {
  const container = useRef<HTMLDivElement>()
  const [, forceUpdate] = useReducer(x => x + 1, 0)
  const {events, hasMore, moreLoading} = useEventsList()
  const {experiments} = useExperiments()
  const loadMoreButtonImprovementsEnabled = isLoadMoreButtonImprovementsEnabled(experiments)

  useEffect(() => {
    const onResize = () => {
      forceUpdate()
    }
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  return (
    <div className={s.background}>
      <div ref={container} className={s.container}>
        <MainListTitle />
        <ul className={s.cards} data-hook={DH.cards}>
          {events.map(event => (
            <Card event={event} t={t} key={event.id} />
          ))}
        </ul>
        {loadMoreButtonImprovementsEnabled ? (
          <div className={s.loadMoreWrapper}>
            <LoadMoreButton hasMore={hasMore} moreLoading={moreLoading} />
          </div>
        ) : (
          <LoadMoreButton hasMore={hasMore} moreLoading={moreLoading} />
        )}
      </div>
    </div>
  )
}
