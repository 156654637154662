import {useExperiments} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {Spinner as TPASpinner, SpinnerProps as TPASpinnerProps} from 'wix-ui-tpa/cssVars'
import {isLoadMoreButtonImprovementsEnabled} from '../../../../../commons/selectors/experiments'
import {classes} from './spinner.st.css'

interface SpinnerProps extends TPASpinnerProps {
  className?: string
  calendar?: boolean
  calendarButton?: boolean
}

export const Spinner = ({className, calendar, calendarButton, ...rest}: SpinnerProps) => {
  const {experiments} = useExperiments()
  const loadMoreButtonImprovementsEnabled = isLoadMoreButtonImprovementsEnabled(experiments)
  return (
    <TPASpinner
      {...rest}
      className={classNames(
        calendar
          ? classes.calendar
          : calendarButton
          ? classes.calendarButton
          : loadMoreButtonImprovementsEnabled
          ? classes.list_LoadMoreButtonImprovements
          : classes.list,
        className,
      )}
    />
  )
}
